import useGlobalNoti from "@hooks/useGlobalNoti";

import React from "react";
import Button from "../Button";
import { Page } from "@apis/common/types";
import { deepLink } from "@apis/common";

export type Type = "app" | "external";

interface CopyButtonProps {
  text: string;
  type: Type;
}

const CopyButton: React.FC<CopyButtonProps> = ({ text, type }) => {
  const { showConfirmNoti, showNoti } = useGlobalNoti();

  const copyLink = async (type: Type) => {
    const url = window.location.href;
    const id = url.split("/").pop();

    if (!id) return;

    let link = "";
    let targetType = "";

    if (url.includes("faq")) {
      targetType = "faq";
    } else if (url.includes("event")) {
      targetType = "event";
    } else if (url.includes("notice")) {
      targetType = "notice";
    } else if (url.includes("charger")) {
      targetType = "manual";
    } else if (url.includes("charging")) {
      targetType = "manual";
    }

    if (type === "app") {
      link = `app/${targetType}=${id}`;
    } else {
      link = await deepLink(targetType as Page, id);
    }

    try {
      navigator.clipboard.writeText(link);
      showConfirmNoti({
        text: "링크가 복사되었습니다.",
      });
    } catch (e) {
      showNoti({
        notiProps: {
          text: `링크 복사에 실패했습니다.\n ${e}`,
        },
        notiType: "ConfirmNoti",
      });
    }
  };

  return (
    <Button
      buttonSize="small"
      buttonType="line_black"
      text={text}
      onClick={() => {
        copyLink(type);
      }}
    />
  );
};

export default CopyButton;
